import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import LinkTo from '../LinkTo'

export const PhotoWrapper = ({linkTo, ...restProps}) => {
    if (linkTo) return <LinkTo linkTo={linkTo}><Photo {...restProps}/></LinkTo>
    return <Photo {...restProps} />
}

export const Photo = ({photo, style, className}) =>  <div className={`text-center hover:cursor-pointer rounded-full border-4 mx-auto border-solid hover:border-green-800 border-transparent overflow-hidden transition ${className}`} style={{...style}}>
<Img fixed={photo.fixed} alt="Headshot" style={{ ...style }} className="mx-auto absolute " /> 
</div>


Photo.defaultProps = {
    style: {
        width : 100, 
        height: 100
    }
   
}

Photo.propTypes = {
    photo: PropTypes.object.isRequired,
    style: PropTypes.object,
    className: PropTypes.string
}
PhotoWrapper.propTypes = {
    linkTo: PropTypes.string,
    restProps: PropTypes.object
}

export default PhotoWrapper
