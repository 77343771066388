import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import LinkTo from '../LinkTo'
import Card from '../elements/Card'
import Photo from './Photo'

const Profile = ({ profile, description, links, socialLinks, active}) => {
    return (
        // Make class
        <Card className="mt-0">
           <Photo photo={profile} className={active ? 'border-highlight' : ""}  linkTo="/about"/>
            
            <p className="text-md">{description}</p>
            {/* links */}
            <div className="mt-2">
                { <LinksList links={links}/> }
            </div>
            {/* Social links */}
            <div className="text-center flex justify-center mt-4 mb-2 ">
                <SocialLinksList socialLinks={socialLinks} />
            </div>
            <BlogCTA />
        </Card>
    )
}


export const LinksList = ({links}) => links.map( link => 
    <LinkTo key={link.linkTo} className="w-full my-2 text-lg lg:text-sm block" linkTo={link.linkTo}>
        <div className="w-8 inline-block">
        { link.faIcon && !link.icon ?  <i className={`fa  text-lg mr-2 w-8 ${link.faIcon}`}></i> : ""}
        { link.icon && link.icon.fixed ?  <Img fixed={link.icon.fixed}/> : link.icon && link.icon.file ?  <img width="25" className="inline-block" src={link.icon.file.url} alt={link.icon.title} /> : "" }
        </div>
        <span className=" hover:text-green-800 text-lg">
            {link.text}
        </span>
    </LinkTo>)


export const SocialLinksList = ({socialLinks}) => socialLinks.map( link => 
    <LinkTo key={link.linkTo} className="my-2 text-xl bg-black mx-2 inline-block w-12 h-12 rounded-full text-white flex flex-col justify-center text-center hover:bg-white hover:text-green-800 transition duration-100" linkTo={link.linkTo}>
        { link.faIcon && !link.icon ?  <i className={`fab text-2xl ${link.faIcon}`}></i> : ""}
        { link.icon && link.icon.fixed ?  <Img fixed={link.icon.fixed}/> : link.icon && link.icon.file ?  <img width="25" className="inline-block" src={link.icon.file.url} alt={link.icon.title} /> : "" }
    </LinkTo>)


export const BlogCTA = ({linkTo}) => <p className="text-xs text-center"> Like the blog? <br/> Make it your own <a className="hover:text-green-800 underline" href={linkTo} target="_blank" rel="noopener noreferrer">here</a> .</p>


BlogCTA.defaultProps = {
    linkTo: "https://themeforest.net/tags/gatsby%20js?gclid=EAIaIQobChMI8PuLi9fL5wIVhrHtCh0UEgHzEAMYAiAAEgJl-vD_BwE"
}


BlogCTA.propTypes = {
    linkTo: PropTypes.string
}
SocialLinksList.propTypes = {
    socialLinks: PropTypes.arrayOf(PropTypes.shape({
        linkTo: PropTypes.string.isRequired,
        faIcon: PropTypes.string,
        icon: PropTypes.object
    }))
}
LinksList.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        linkTo: PropTypes.string.isRequired,
        faIcon: PropTypes.string,
        icon: PropTypes.object,
        text: PropTypes.string
    }))
}
Profile.propTypes = {
    profile: PropTypes.object.isRequired,
    description: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape({
        linkTo: PropTypes.string.isRequired,
        faIcon: PropTypes.string,
        icon: PropTypes.object,
        text: PropTypes.string
    })),
    socialLinks: PropTypes.arrayOf(PropTypes.shape({
        linkTo: PropTypes.string.isRequired,
        faIcon: PropTypes.string,
        icon: PropTypes.object
    })),
    active: PropTypes.bool
}

export default Profile
