import React from 'react'
import PropTypes from 'prop-types'
import Card from './Card'
import Img from 'gatsby-image'
import * as timeago from 'timeago.js'


const PostCard = ({ header, subHeader, body, className, dateString, linkTo, coverImage }) => {
    
    return (
        <Card className={`pb-0 pt-0 pl-0 pr-0 relative my-6 ${className}`} linkTo={linkTo}>
            <article className="flex flex-col sm:flex-row order-2">
                <div className={` ${!dateString ? "px-4 py-6" : "p-4"} w-full ${coverImage ? "sm:w-3/5" : "w-full"} `}>
                    {dateString && <p className="text-sm font-bold text-gray-600 mb-0">{timeago.format(dateString)}</p>}
                    <h2 className="text-2xl mb-1">{header}</h2>
                    <h5 className="text-base font-normal font-body">{subHeader}</h5>
                </div>

                {coverImage ?
                    <div className="w-full sm:w-2/5 ml-auto h-48 sm:h-full overflow-hidden order-first sm:order-2 sm:absolute right-0">
                        <Img className="h-full sm:rounded-post-row rounded-post-col" fluid={coverImage.fluid} alt={coverImage.title} />
                    </div> : ""
                }

            </article>
        </Card>
    )
}

PostCard.propTypes = {
    header: PropTypes.string.isRequired,
    dateString: PropTypes.string,
    linkTo: PropTypes.string.isRequired
}

export default PostCard
