// CPHFPVVHBL 


export function useSubscribe() {
    return {
        subscribe: (body) => subscribe(body)
    }
}


async function subscribe({ email = "test", name = "doe", consent = false }) {
    try {
        // throw Error()
        let result = await fetch("/.netlify/functions/emailSubscription", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, name, consent })
        })


        let data = await result

        // Data converts and extracts message
        data = await data.json()
        return data
    } catch (err) {
        console.log('Error', err)
        return {
            success: false,
            error: "Failed to subscribe"
        }
    }

}