import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '../elements/Card'
import { useSubscribe } from '../../hooks/useSubscribe'
import { useEffect } from 'react'

export const SubscriptionHeader = ({ header }) => <h5>{header}</h5>
export const SubscriptionBody = ({ body }) => <p className="md:text-md text-base mb-2">{typeof body === 'object' ? body.description : body}</p>


export const SubscriptionInput = ({ buttonText, setSubscribedMessage }) => {

    const initialInput = {
        email: {
            input: "",
            validation: (input) => input && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(input) ,
            valid: ""
        },
        name: {
            input: "",
            validation: (input) => input && input.length > 2 && /^[A-z ]+$/gm.test(input),
            valid: ""
        },
        consent: {
            input: "",
            validation: (input) => input,
            valid: ""
        }
    }

    const [input, setInput] = useState(initialInput)
    const [disabled, setDisabled] = useState(true)
    const [active, setActive] = useState(false)

    const { subscribe } = useSubscribe();

    useEffect(() => {
        const valid = Object.keys(input).map(key => { 
                return input[key].validation(input[key].input)
            })
            .filter(value => !value).length > 0
        if (disabled !== valid) setDisabled(valid)
       
    }, [input, disabled])

    
 
    const handleClick = async (e) => {

        e.preventDefault();
        const { email, name, consent } = input;


        // Wait for the message request to resolve
        let message = await subscribe({ email: email.input, name: name.input, consent: consent.input })

        if (message.success) {
            setSubscribedMessage("Thank you for subscribing.")
            // Reset the forms
            setInput(initialInput)
            return setActive(false) 
        }
        if (!message.success) {
            setSubscribedMessage("Oh dear, appears something has gone wrong... Please try again later. If this problem persists please reach out.")

            // Reset the forms
            setInput(initialInput)
            return setActive(false)
        }
    }

    const handleCheck = (check) => {       
        if (input.consent === check ) return 
        return setInput({
            ...input,
            consent: {
                ...input.consent,
                input: check 
            }
        })
    }

    const handleChange = (e) => {
        const name = e.currentTarget.name || e.currentTarget.id
        setInput({
            ...input,
            [name]: {
                ...input[name],
                input: e.currentTarget.value,
            }
        })
    }


    const handleValidation = (e) => {
        const name = e.currentTarget.name || e.currentTarget.id
        setInput({
            ...input,
            [name]: {
                ...input[name],
                valid: input[name].validation(e.currentTarget.value),
            }
        })
    }


    const { email, name, consent } = input
    return (<>
        {/* Add custom email verification and type checking component here */}
        <label htmlFor="email" className="hidden">Email address</label>
        <input
            onBlur={(e) => handleValidation(e)}
            onFocus={() => setActive(true)}
            type="email" 
            name="email" 
            onChange={(e) => handleChange(e)} 
            value={email.input} 
            className={`h-12 bg-gray-light text-gray-dark w-full px-2 text-base outline-none focus:shadow-none  shadow-none ${email.valid === false ? "border border-red-500" : ""}`}  
            placeholder="email@example.com" />

        <div className={`${active ? "h-12 mt-2 mb-2 overflow-hidden" : "h-0 mb-0 opacity-0"} transition-dimensions duration-500 flex relative -z-10 `} >
            <input
                type="text" 
                name="name" 
                required 
                onBlur={(e) => handleValidation(e)}
                onChange={(e) => handleChange(e)} 
                value={name.input} 
                className={`h-12 bg-gray-light text-gray-dark w-full px-2 text-base focus:shadow-outline focus:outline-none ${name.valid  === false ? "border border-red-500" : ""}` }
                placeholder="First Last" 
            />
        </div>


        <div className={`${active ? "h-12 mt-2 mb-2 overflow-hidden" : "h-0 mb-0 opacity-0"} transition-dimensions duration-500 flex relative -z-10 `} >
            <label htmlFor="consent" className="self-center mr-2 text-base">I consent to receive emails: </label>
            <CheckBox 
                validate={ handleValidation}
                setChecked={handleCheck} checked={consent.input} />
        </div>

        { disabled ?  <button disabled className="bg-gray-dark w-full opacity-50 cursor-not-allowed h-12 text-gray-400 text-sm  relative z-10">{buttonText}</button> :  <button onClick={(e) => handleClick(e)} className="bg-gray-dark w-full h-12 text-white text-sm hover:bg-gray-800 transition-bg duration-500 relative z-10">{buttonText}</button>}
    </>)
}


const CheckBox = ({ checked, setChecked, validate=false }) => {
    return (
        <span 
            id="consent"
            onBlur={ validate ? (e) => validate(e) : null}
            onClick={() => setChecked(!checked)} 
            onKeyUp={(e) => e.keyCode === 32 || e.keyCode === 13  ? setChecked(!checked) : ""}
            role="checkbox" 
            tabIndex="0" 
            aria-checked={checked} 
            className={`${checked ? "bg-green-800" : "bg-gray-600"} overflow-hidden relative inline-block flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none mt-3 focus:border-green-400`}>
            <span aria-hidden="true" className={`${checked ? "translate-x-6" : "translate-x-0"} relative inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}>
                <span className={`${checked ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200"} absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}>
                    <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                        <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
                <span className={`${checked ? "opacity-100 ease-in duration-200" : "opacity-0 ease-out duration-100"}  absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}>
                    <svg className="h-3 w-3 text-green-800" fill="currentColor" viewBox="0 0 12 12">
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                </span>
            </span>
        </span>
    )
}

export const SubscriptionFull = ({ header, body, buttonText, className }) => {

    const [subscribedMessage, setSubscribedMessage] = useState(false)
    return <Card className={className}>
        <SubscriptionHeader header={header} />
        <SubscriptionBody body={subscribedMessage ? subscribedMessage : body} />
        <SubscriptionInput buttonText={buttonText} setSubscribedMessage={setSubscribedMessage} />
    </Card>

}

SubscriptionFull.defaultProps = {
    body: "Become a subscriber to get details of new articles straight in your inbox. Unsubscribe any time.",
    header: "Subscribe",
    buttonText: "Subscribe"
}

SubscriptionFull.propTypes = {
    header: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    buttonText: PropTypes.string
}

export default SubscriptionFull
