import React, { useState } from 'react'
import LinkTo from './LinkTo'
import Profile, { LinksList, SocialLinksList, BlogCTA } from './NavBar/Profile'
import { navigate } from 'gatsby'
import Subscription from './NavBar/Subscription'
import MenuIcon from '../images/menu-icon.svg'
import Cross from '../images/cross.svg'
import Photo from './NavBar/Photo'
import { SubscriptionHeader, SubscriptionBody, SubscriptionInput } from './NavBar/Subscription'
import UseBodyLock from '../hooks/useBodyLock'


const Nav = ({ path, nav, className, style }) => {


    const { description, socialLinks, profile, links } = nav.nodes[0];
    const { emailSubscription, categories } = nav.nodes[0];

    return (
        <>
            <nav style={{ minWidth: 260, ...style }} className={`hidden md:block ${className}`}>
                {/* Profile */}
                <Profile description={description} profile={profile} socialLinks={socialLinks} links={links} active={path === "/about"} />
                <CategoriesList className="xl:hidden block" categories={categories} path={path} />
                {/* Email  */}
                {emailSubscription ? <Subscription {...emailSubscription} /> : ""}

            </nav>


        </>
    )
}


export const MobileNav = ({ path, nav, className, style }) => {

    const { description, socialLinks, profile, links } = nav.nodes[0];
    const { emailSubscription, categories } = nav.nodes[0];
    const [menuOpen, setMenuOpen] = useState(false)


    // Lock the body behind
    UseBodyLock(menuOpen)
    return <nav className={`block md:hidden  ${className}`} style={{ zIndex: 100, ...style }}>
        <div
            className="fixed mt-5 sm:mt-2 ml-2 z-20 bg-gray-bg p-3 rounded-full transition focus:outline-none focus:shadow-outline"
            tabIndex="0"
            role="button"
            onKeyUp={(e) => e.keyCode === 32 || e.keyCode === 13 ? setMenuOpen(!menuOpen) : ""}
            onClick={() => setMenuOpen(!menuOpen)}>
            {!menuOpen ? <img className="w-6 h-6" src={MenuIcon} alt="menu icon" /> : <img className="w-6 h-6" src={Cross} alt="menu icon" />}
        </div>
        <div className={`right-0 mr-2 mt-4 z-20  rounded-full ${menuOpen ? "fixed" : "absolute"}`}>
            <Photo photo={profile} className={path === "/about" ? "border-green-800" : ""} linkTo="/about" style={{ width: 60, height: 60 }} />
        </div>
        <div
            style={{ height: menuOpen ? '100vh' : '0vh', width: menuOpen ? '100vw' : '100vw', paddingTop: menuOpen ? '3rem' : '0rem' }}
            className="bg-gray-bg fixed h-screen w-screen z-10 top-0 transition overflow-y-scroll">
            <div className="absolute overflow-hidden transition duration-1000 px-5 pt-8" style={{ opacity: menuOpen ? 1 : 0 }}>
                <p className="text-md mb-4">{description}</p>
                <h3 className="mb-0 text-2xl">Categories</h3 >
                <CategoriesList categories={categories} Component={Category} path={path} />
                <h4 className="mb-0 text-xl mt-4">Other Links</h4 >
                <LinksList links={links} />
                <div className="my-4 max-w-md">
                    <SubscriptionHeader header={emailSubscription.header} />
                    <SubscriptionBody body={emailSubscription.body} />
                    <SubscriptionInput buttonText={emailSubscription.buttonText} />
                </div>
                <div className="flex justify-center">
                    <SocialLinksList socialLinks={socialLinks} />
                </div>
                <BlogCTA />
            </div>
        </div>
    </nav>
}


export const PostNav = ({ path, nav, className, style, location }) => {

    const { profile } = nav.nodes[0];

    return <nav className={`md:block hidden absolute w-full h-20 z-20 bg-gray-bg `} style={{ ...style }}>
        <div className={`flex justify-between mx-auto w-full bg-gray-bg pt-2 z-20 max-w-5xl ${className}`}>
            <button className="z-20 bg-gray-bg self-center py-2 px-4 rounded-2 w-1/5 transition text-center "
                onClick={() => navigate(location.state?.referrer || "/")} >
                {"< "}Back
            </button>
            <div className="rounded-full relative z-20 mr-4 ">
                <Photo photo={profile} className={path === "/about" ? "border-green-800" : ""} linkTo="/about" style={{ width: 60, height: 60 }} />
            </div>

        </div>
    </nav>
}






export const CategoriesList = ({ categories, Component, path, className }) => {
    return <ul className={className}>
        <LinkTo linkTo={`/`}>
            <Component>
                {path === "/" ? <b className="text-highlight">All</b> : "All"}
            </Component>
        </LinkTo>
        {
            categories && categories.map(category => {
                const disabled = !category.post
                return <LinkTo key={category.id} linkTo={`/${category.slug.toLowerCase()}`}>
                    <Component disabled={disabled}>
                        {path === `/${category.slug}` && !disabled ? <b className="text-highlight">{category.name}</b> : category.name}
                    </Component>
                </LinkTo>
            })
        }
    </ul>
    }


export const CategoryButton = ({ children, disabled = false }) =>
    <button
        disabled={disabled}
        className={`p-4 w-full bg-white my-2 font-display  ${disabled ? 'text-gray-400 bg-gray-100 hover:cursor-default' : 'hover:text-green-800 transition duration-500 hover:shadow-xl shadow-subtle'} `} >
        {children}
    </button>
export const Category = ({ children, disabled = false }) => {
    return !disabled ? <li
        className={`font-body block text-md my-1 hover:text-green-800 transition duration-500 `} >
        {children}
    </li> : ""
}


CategoriesList.defaultProps = {
    Component: (props) => CategoryButton(props),
    categories: []
}


export default Nav
