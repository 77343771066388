import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const LinkTo = ({linkTo, children, className}) => {

    const externalLink = linkTo.includes("http") || linkTo.includes("www")
    if (externalLink) return ( <a  className={className} href={linkTo} target="_blank" rel="noopener noreferrer">{children} </a>)
    return (
        <Link className={className} to={linkTo} >{children}</Link>
    )
}

LinkTo.defaultProps = {
    linkTo: "/"
}

LinkTo.propTypes = {
    linkTo: PropTypes.string.isRequired,
}

export default LinkTo
