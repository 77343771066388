import React from 'react'
import PropTypes from 'prop-types'
import LinkTo from '../LinkTo'

const Card = ({children, linkTo, className, style}) => {
    if ( linkTo ) {
        return <LinkTo className="hover:cursor-pointer " linkTo={linkTo}>
            <CardWrapper className={className} style={{...style}}>
                { children }
            </CardWrapper>
        </LinkTo>
    }
    return (
        <CardWrapper className={className} style={{...style}}>
        { children }
        </CardWrapper> 
    )
}

const CardWrapper = ({children, className, style}) => {
    return <section style={{...style}}
    className={`w-full bg-white shadow-subtle p-4 rounded-sm my-4 hover:shadow-xl transition-shadow duration-500 rounded-md ${className}`}>
    { children }
    </section>
}

Card.propTypes = {
    linkTo: PropTypes.string,
    className: PropTypes.string
}

export default Card
