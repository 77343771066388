import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent";

import SEO from './SEO'
import Nav, { CategoriesList, MobileNav } from "./Nav"


const Layout = (props) => {

    const data = useStaticQuery(graphql`
    query LayoutQuery {
        nav: allContentfulNav {
            nodes {
                id
                description
                socialLinks {
                faIcon
                icon {
                    title
                    fixed(width: 100, height: 100, quality: 100) {
                        src
                    }
                }
                linkTo
                text
                }
                profile {
                    fixed(width: 100, height: 100, quality: 100) {
                        ...GatsbyContentfulFixed
                    }
                title
                }
                links {
                    faIcon
                    icon {
                        title
                        file {
                            url
                        }
                    }
                    linkTo
                    text
                }
                categories {
                    name
                    slug
                    id
                    post {
                        id
                    }
                }
                emailSubscription {
                    buttonText: button
                    body: description {
                        description
                    }
                    header
                }
            }
        }
    }
    `)

    const { header, title, description, image, path, children } = props;
    const { nav } = data;

    return (
        <>
            <SEO title={title} description={description} image={image} />
            {/* <Header className="w-1/4 min-h-screen" /> */}
            <div className="max-w-screen-xl mx-auto min-h-screen flex md:pl-2">
                <MobileNav path={path} nav={data.nav} />

                <div className="hidden md:block md:w-2/5 lg:w-1/5 relative " style={{ minWidth: 260 }}>
                    <Nav className="mt-12 md:sticky" style={{ top: 48, minWidth: 260 }} path={path} nav={nav} />
                </div>
                <main className="lg:w-full xl:w-3/5 px-3 md:px-4 mt-24 lg:mt-12">
                    {header ? <h1 className="text-3xl font-bold">{header}</h1> : ""}
                    {children}
                </main>


                <div className="hidden md:block lg:w-1/6 xl:w-1/5 relative ">
                    <div className="hidden xl:block mt-12 sticky right-0 pr-2" style={{ minWidth: 200, top: 45 }}>
                        <CategoriesList categories={nav.nodes[0].categories} path={props.path} />
                    </div>

                </div>
            </div>
            <CookieConsent
                acceptOnScroll={true}
                acceptOnScrollPercentage={15}
            >
                This website uses cookies to anonymously enhance the user experience.
            </CookieConsent>
            <footer className="text-center py-6">
                © {new Date().getFullYear()} Sam Loyd
            </footer>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
